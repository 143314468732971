export default {
  async update (Vue, id, formData) {
    return await Vue.$api.call(
      'lparteNovedad.update',
      {
        values: {
          idlparte_novedad: id,
          fnovedad: formData.fnovedad,
          idtnovedad: formData.tnovedad.idtnovedad,
          idfuerza_seguridad: formData.idfuerza_seguridad,
          descripcion: formData.descripcion,
          informe_cliente: formData.informe_cliente,
          idpunto: formData.idpunto,
          idpuesto_servicio_servicio: formData.idpuesto_servicio_servicio
        },
      }
    )
  },
}
